export const Logo = require('./logo.png')
export const Banner1 = require('./banner-1.jpg')
export const Banner2 = require('./banner-2.jpg')
export const Gallery1 = require('./gallery-1.jpg')
export const Gallery2 = require('./gallery-2.jpg')
export const Gallery3 = require('./gallery-3.jpg')
export const Gallery4 = require('./gallery-4.jpg')
export const Lesson1 = require('./lesson-1.jpg')
export const Rental1 = require('./rental-1.jpg')
export const Package1 = require('./package-1.png')
export const Merch1 = require('./merch-1.jpg')
export const Merch2 = require('./merch-2.jpg')
export const Merch3 = require('./merch-3.jpg')
export const Reservation1 = require('./reservation-1.jpg')
export const BookNow = require('./book-now.png')