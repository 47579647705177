import React from 'react'

export default function GaleryItem(props) {

    return (
        <div className={"col-sm-12 col-md-6 col-lg-3 galery-item-image text-center d-flex justify-content-center mb-5"}>
            <img className={"w-100"} src={props.img} alt={props.name} />
            <div className={"galery-item-button"}>
                <h3>{props.name.toUpperCase()}</h3>
                <button type="button" className={"btn btn-galery-item px-4"}>
                    <a href={`#${props.id}`}>
                        <h5 className={"m-0"}>EXPLORE</h5>
                    </a>
                </button>
            </div>
        </div>
    )
}
