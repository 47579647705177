import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Follow() {
    return (
        <div className="text-left">
            <h2>Follow us on</h2>
            <div className="social-network">
                <a href="https://www.instagram.com/foilbali/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'instagram']} className={"mr-2"} size="2x" />
                </a>
                <a href="https://www.facebook.com/foilbali/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'facebook']} className={"mr-2"} size="2x"/>
                </a>
                <a href="https://www.tripadvisor.com/Attraction_Review-g608487-d1627515-Reviews-Rip_Curl_School_Of_Surf-Legian_Bali.html" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'tripadvisor']} className={"mr-2"} size="2x" />
                </a>
                <a href="https://www.youtube.com/channel/UC02OnjziKkQo3WmPuR8yCWQ?view_as=public" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'youtube']} className={"mr-2"} size="2x" />
                </a>
            </div>
        </div>
    )
}

export default Follow
