import React, { Component } from 'react'

class Clip extends Component {
    render() {
        return (
            <div className={"mt-5 mx-5"} id="clips">
                <h2 className={"clip-header mb-3"}>CLIPS</h2>
                <div className={"clip-video my-3"}>
                    <iframe 
                        title="Foil Bali"
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/FTXQc-WcywA?controls=0" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                </div>
                <p className={"paragraph-text"}>Checkout our students, crew and friends taking on world class waves and
                    the best foil conditions all here in Sanur. Our videographer and drone
                    operators are available for hire to capture your sessions too, inquire for
                    rates.
                </p>
            </div>
        )
    }
}

export default Clip
