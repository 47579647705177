import React from 'react'
import Contact from './Contact'
import Follow from './Follow'
import Address from './Address'
import Subscribe from './Subscribe'

function Footer() {
    return (
        <footer id="contact-us">
            <div className="row m-0 px-5">
                <div className="col-md-6 my-3">
                    <Address/>
                </div>
                <div className="col-md-6 my-3">
                    <Follow/>
                </div>

                <div className="col-md-6 my-3">
                    <Contact/>
                </div>
                <div className="col-md-6 my-3">
                    <Subscribe/>
                </div>
            </div>
        </footer>
    )
}

export default Footer
