import React from 'react'
import { Banner1, Banner2 } from '../../assets/images'

function Banner() {
    return (
        <div>
            <div id="carouselBanner" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <a href="#reservation">
                            <img src={Banner1} className="d-block w-100" alt={"Foil Bali"} />
                        </a>
                    </div>
                    <div className="carousel-item">
                        <a href="#reservation">
                            <img src={Banner2} className="d-block w-100" alt={"Foil Bali"} />
                        </a>
                    </div>
                </div>
            </div>
            <p className="paragraph-text">
                The art of foiling is a moment in time that will leave you bewildered and 
                amazed. What we thought we knew about riding waves or gliding over the
                water will take on new meaning: connections, power, lift and flying; Learning
                to foil is easier than learning to surf.
            </p>
        </div>
    )
}

export default Banner
