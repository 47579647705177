import React from 'react'
import { Reservation1, BookNow } from '../../assets/images'

function Reservation() {
    return (
        <div className={"mb-5 pt-anchor"} id="reservation">
            <h2 className={"ml-5"} >RESERVATIONS</h2>
            <div className={"row m-0"}>
                <div className={"col-sm-12 col-lg-6 p-0"}>
                    <img src={Reservation1} className={"w-100"} alt={"Reservations"} />
                </div>
                <div className={"col-sm-12 col-lg-6 p-0 reservation-wrapper"}>     
                    <input className="mt-c-btn-buy-now" type="image" src={BookNow} alt="Book Now" data-mt-source="https://megatix.co.id/white-label/foil2023" />
                </div>
            </div>
        </div>
    )
}

export default Reservation
