import React from 'react'
import { Merch1, Merch2, Merch3 } from '../../assets/images'

function Merch() {
    return (
        <div className={"pt-5 mx-5"} id="merchandise">
            <h2>MERCH</h2>
            <div className={"row"}>
                <div className={"col-sm-12 col-lg-6 mb-5"}>
                    <img src={Merch1} className={"w-100"} alt={"merchandise"}/>
                </div>
                <div className={"col-sm-12 col-lg-6 mb-5 image-bottom"}>
                    <img src={Merch2} className={"image-item"} alt={"merchandise"}/>
                    <img src={Merch3} className={"image-item"} alt={"merchandise"}/>
                </div>
            </div>
        </div>
    )
}

export default Merch
