import React from 'react'
import { Package1 } from '../../assets/images'

function Package() {
    const slide = (n) => {
        return (
                <div className={"mx-5"} id="package">
                    <h2 className={"clip-header mb-3"}>PACKAGES</h2>
                    <div className={"row m-0"}>
                        <div className={'col-sm-12 col-lg-7 package-wrapper'}>
                            <h3 className={'mb-3'}>FOIL CLINIC</h3>
                            <p className={"slide-text"}>
                                Our foil clinic is designed to cover
                                ever aspect of your preferred foil
                                discipline; Surf, SUP, Kite or Wind
                                Wing. The first session covers foil
                                knowledge and riding, continued
                                sessions focus on your selected
                                discipline skills.
                            </p>
                            <div className={"row justify-content-center"}>
                                <span className={`dot ${n === 1 && "dot-active"}`}></span>
                                <span className={`dot ${n === 2 && "dot-active"}`}></span>
                                <span className={`dot ${n === 3 && "dot-active"}`}></span>
                            </div>
                        </div>
                        <div className={'col-sm-12 col-lg-5 px-0'}>
                            <img src={Package1} alt="Packages" className={"w-100"}/>
                        </div>
                    </div>
                </div>
        )
    }

    return (
        <div id="package" className={"pt-anchor"}>
            <div id="carouselPackages" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item p-0 active">
                        { slide(1) }
                    </div>
                    <div class="carousel-item p-0">
                        { slide(2) }
                    </div>
                    <div class="carousel-item p-0">
                        { slide(3) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Package
