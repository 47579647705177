import React, { Component } from 'react'
import { Rental1 } from '../../assets/images'

class Rental extends Component {
    render() {
        return (
            <div id={"rental"} className={"mx-5 pt-anchor"} >
                <h2>RENTAL</h2>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <img src={Rental1} className={"w-100"} alt={"Rental"} />
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Rental
