import React from 'react'
import { Lesson1 } from '../../assets/images'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Lesson() {
    const slide = (n) => {
        return (
            <div>
                <h2 className={"clip-header ml-5"}>LESSON</h2>
                <div className={"row m-0"}>
                    <div className={'col-sm-12 col-lg-8 px-0'}>
                        <img src={Lesson1} alt="Student" className={"w-100"}/>
                    </div>
                    <div className={'col-sm-12 col-lg-4 lesson-wrapper'}>
                        <h3>CLASS</h3>
                        <p className={"slide-text"}>
                            Our lessons cover
                            foil knowledge and
                            riding skills; starts
                            in kite, SUP, wind
                            wing and surfing.
                        </p>

                        <div className={"row justify-content-center"}>
                            <span className={`dot ${n === 1 && "dot-active"}`}></span>
                            <span className={`dot ${n === 2 && "dot-active"}`}></span>
                            <span className={`dot ${n === 3 && "dot-active"}`}></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="lesson" className={"pt-anchor"}>
            <div id="carouselLessons" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item p-0 active">
                        { slide(1) }
                    </div>
                    <div className="carousel-item p-0">
                        { slide(2) }
                    </div>
                    <div className="carousel-item p-0">
                        { slide(3) }
                    </div>
                
                </div>

                <a className="carousel-control-prev" href="#carouselLessons" role="button" data-slide="prev">
                    <FontAwesomeIcon icon="angle-left" size="2x" color="gray"/>
                </a>
                <a className="carousel-control-next" href="#carouselLessons" role="button" data-slide="next">
                    <FontAwesomeIcon icon="angle-right" size="2x" color="gray"/>
                </a>
            </div>
        </div>
        )
}

export default Lesson
