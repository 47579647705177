import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPhoneSquare, faEnvelopeSquare, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './page/home/Home'

library.add(fab, faPhoneSquare, faEnvelopeSquare, faAngleLeft, faAngleRight)

class App extends React.Component {
  render() {
    return (
      <Router>
        <Route name={"home"} exact path={"/"} component={Home} />
      </Router>
    )
  }

}
export default App
