import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Contact() {
    return (
        <div className="text-left">
            <h2>Contact Us</h2>
            <div className="contact-detail">
                <FontAwesomeIcon icon="phone-square" /> +62 361-735-858  <br/>
                <FontAwesomeIcon icon={['fab', 'whatsapp-square']} /> +62-812-12735858 <br/>  
                <FontAwesomeIcon icon="envelope-square" /> info@ripcurlschoolofsurf.com
            </div>
        </div>
    )
}

export default Contact
