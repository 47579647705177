import React from 'react'
import { Logo } from '../../assets/images'

function Header() {
    const [link, setLink] = React.useState('home')

    const links = [
        { name: 'home', title: 'Home' },
        { name: 'lesson', title: 'Lesson' },
        { name: 'rental', title: 'Rental' },
        { name: 'package', title: 'Packages' },
        { name: 'reservation', title: 'Reservation' },
        { name: 'contact-us', title: 'Contact Us' }
    ]

    const renderLink = (index, name, title) => {
        return (            
            <li className={`nav-item ${link === name && 'active'}`} key={index}>
                <a className="nav-link" href={`#${name}`} onClick={() => setLink(name)}>{title}</a>
            </li>
        )
    }

    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light sticky-top">
            <div className="container-fluid">
                <a href={'#home'} className="navbar-brand"> <img src={Logo} alt="Logo"/></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        {
                            links.map((link, index) => renderLink(index, link.name, link.title))
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header
