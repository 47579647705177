import React from 'react'

function Address() {
    return (
        <div className="text-left">
            <h2>Our Address</h2>
            <p>
                Prama Sanur Beach Hotel <br/>
                Jalan Cemara, Sanur, Bali
            </p>
        </div>
    )
}

export default Address
