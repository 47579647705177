import React from 'react'

function Subscribe() {
    return (
        <div className="text-left">
            <h2>Newsletter</h2>
            <form>
                <div>
                    <label className="sr-only" htmlFor="inlineFormInputGroupUsername">Email</label>
                    <div className="input-group w-50">
                        <input type="text" className="form-control" placeholder="Email Address"/>
                        <div className="input-group-prepend">
                            <div className="input-group-text bg-primary text-white">Subscribe</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Subscribe
