import React from 'react'
import GaleryItem from '../partial/GaleryItem'
import { Gallery1, Gallery2, Gallery3, Gallery4 } from "../../assets/images"

function Gallery() {
    return (
        <div className={"container mt-5"}>
            <div className={"row"}>
                <GaleryItem img={Gallery1} name={"Lessons"} id={"lesson"}/>
                <GaleryItem img={Gallery2} name={"Rentals"} id={"rental"} />
                <GaleryItem img={Gallery3} name={"Packages"} id={"package"} />
                <GaleryItem img={Gallery4} name={"Reservations"} id={"reservation"} />
            </div>
        </div>
    )
}

export default Gallery
