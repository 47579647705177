import React from 'react'
import Header from '../partial/Header'
import Gallery from './Gallery'
import Footer from '../partial/Footer'
import Banner from './Banner'
import Lesson from './Lesson'
import Clip from './Clip'
import Merch from './Merch'
import Rental from './Rental'
import Package from './Package'
import Reservation from "./Reservation"

function Home (){
    return (
        <div id="home">
            <Header />
            <Banner />
            <Gallery />
            <Clip />
            <Lesson />
            <Rental />
            <Package />
            <Merch />
            <Reservation />
            <Footer />
        </div>
    )
}

export default Home
